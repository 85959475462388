@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body{
    font-family: 'Oswald', sans-serif;
    overflow-x: hidden;
}

#hero{
    width: 100%;
    height:100vh;
    background: url("../img/herobg.jpg") no-repeat center center/cover;
    display:flex;
    flex-direction: column;
    position:relative;
}

#hero::before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    min-height:100vh;
    background:rgba(0, 0, 0, 0.7);
    z-index:0;
}

#hero-content{
    position:relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    width:100%;
    height:100%;
    color: rgb(221, 221, 221);
    font-family: 'Oswald', sans-serif;
    z-index: 2;
}

#hero-content h1{
    padding: 25px 15px; 
    font-size: 3.5em;
}

#hero-content h1 span{
    color:rgb(60, 3, 3);
}

#hero-content h2{
    padding-bottom: 25px;
    font-size: 2em;
}


#hero-content p{
    font-size: 18px;
    padding: 0px 10px;
}

#hero-content #resumebtn{
    text-align: center;
    color:rgb(221, 221, 221);;
    text-decoration: none;
    border: 1px solid rgb(60, 3, 3);
    width: 150px;
    height:40px;
    margin-top: 15px;
    margin-left:auto;
    margin-right:auto;
    padding-top:5px;
}

#hero #arrow{
    text-align: center;
    text-decoration: none;
    padding-bottom: 20px;
    color: rgb(60, 3, 3);
    z-index: 2;
}


#hero #Arrow:hover{
    color:rgb(71, 4, 4);
}

#hero-content #resumebtn:hover{
    border: 1px solid rgb(71, 4, 4);
    background: rgb(71, 5, 5);
    transition: ease-in-out 0.3s;

}

@media only screen and (max-width: 800px) {
    #hero-content h1{
        padding: 10px 0px; 
        font-size: 3em;
    }

    #hero-content h2{
        font-size:1.5em;
        padding-bottom: 10px;
    }

    #hero-content p{
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px){
    #hero-content h1{
        padding: 10px 5px; 
        font-size: 2.6em;
    }

    #hero-content h2{
        font-size:1.3em;
        padding-bottom: 10px;
    }

    #hero-content p{
        font-size: 14px;
    }
}