@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body{
    font-family: 'Oswald', sans-serif;
    color:rgb(221, 221, 221);
    overflow-x: hidden;
}

#projects{
    text-align: center;
    min-height:100vh;
    width: 100%;
    background: rgb(8, 10, 12);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#projects h1{
    padding: 25px 0px;
    font-size: 3em;
}

#projects #projectsContainer{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    margin-bottom:100px;
}

@media only screen and (max-width: 450px){
    #projects h1{
        font-size: 2.5em;
    }
}