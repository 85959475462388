@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body{
    font-family: 'Oswald', sans-serif;
    color:rgb(221, 221, 221);
    overflow-x: hidden;
}

#skills{
    width: 100%;
    min-height: 60vh;
    background: rgb(8, 10, 12);
    text-align: center;
}

#skills h1{
    font-size: 3em;
}

#skills #SkillsContainer{
    display:flex;
    align-content: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width:100%; 
    min-height: 50vh;
    padding-top: 25px;
    padding-bottom: 15px;
}

#skills #SkillsContainer .SkillsBox{
    display:flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: column;
    text-align: center;
    width:500px;
    max-width:500px;
    min-height:45vh;
    background: rgb(14, 17, 20);
    border-radius: 5px;
    margin:25px;
    transition: all ease-in-out 0.25s;
}

#skills #SkillsContainer .SkillsBox:hover{
    transform: rotateX(0deg) rotateY(0deg) rotateZ(1deg)
             translateX(5px) translateY(-16px);
    transform-origin: center center;
    -webkit-box-shadow: 8px 8px 4px -3px rgba(36,36,36,0.38); 
    box-shadow: 8px 8px 4px -3px rgba(36,36,36,0.38);
}

#skills #SkillsContainer .SkillsBox h2{
    font-size: 2em;
}

#skills #SkillsContainer .SkillsBox .skillicon{
    align-self: center;
    color:rgb(60, 3, 3);
}

#skills #SkillsContainer .SkillsBox p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    padding: 10px;
}

@media only screen and (max-width: 800px) {

    #skills h1{
        font-size: 2.5em;
    }
}