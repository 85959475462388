@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body{
    font-family: 'Oswald', sans-serif;
}

#project{
    display:flex;
    justify-content: space-around;
    align-items: center;
    background:rgb(14, 17, 20);
    width:95%;
    min-height: 400px;
    margin: 20px 0;
}

#project .thumbnail{
    width: 50%;
    min-height: 100%;
}

#project .thumbnail .thumbnaillink:hover{
    filter: brightness(0.6);
}


#project .projectinfo{
    width:50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.siteimg{
    width: 95%;
    margin: 10px 0;
    height: auto;
}

#project .projectinfo h1{
    font-size: 2.5em;
}

#project .projectinfo p{
    padding:0 15px; 
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
}

#project .projectinfo .technologies{
    display:flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px;
    color: rgb(221, 221, 221);
}


#project .projectinfo .technologies .tech{
    margin: 10px 5px;
    height: 40px;
    width: 100px;
    border: rgb(85, 5, 5) 1px solid;
    padding: 5px 10px;
    border-radius: 5px;
}

#project .projectinfo .buttons .links{
    text-decoration: none;
    color:rgb(221, 221, 221);
    margin: 10px 20px;
    padding: 5px 10px;
    background:rgb(60, 3, 3);
    border-radius: 5px;
}

#project .projectinfo .buttons .links:hover{
    background:rgb(48, 2, 2);
}

span{
    color:rgb(60, 3, 3);
}

@media only screen and (max-width: 1050px){
    #project{
        flex-direction: column;
        width:90%;
    }

    #project .projectinfo{
        width:100%;
        height:35%;
        padding-bottom: 50px;
    }

    #project .thumbnail{
        width:100%;
        height: 65%;
    }

    
}

@media only screen and (max-width: 450px){
    .siteimg{
        width:100%;
    }

    #project .projectinfo h1{
        font-size: 1.5em;
    }

    #project .projectinfo p{
        font-size: 0.9em;
    }

    #project .projectinfo .technologies{
        margin:10px;
        
    }

    #project .projectinfo .technologies .tech{
        width: 80px;
        height: 30px;
        font-size: 0.8em;
        margin: 5px;
        
    }

    #project .projectinfo .buttons .links{
        margin: 5px 10px;
        padding: 3px 7px;
    }
}