@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body{
    font-family: 'Oswald', sans-serif;
    color:rgb(221, 221, 221);
    overflow-x: hidden;
}

#AboutSection{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 40vh;
    background: rgb(8, 10, 12);
}

#AboutSection #details{
    width:93%;
    min-height: 70%;
    background: rgb(14, 17, 20);
    border-radius: 25px;
    margin: 15px 0px;
}

#AboutSection #details #About h1{
    font-size: 3em;
    padding: 5px 0px;
    padding-left: 20px;
    
}

#AboutSection #details #About h1 span{
    color:rgb(60, 3, 3);
}

#AboutSection #details #About p{
    padding: 0px 50px;
    padding-bottom: 15px;
    line-height: 2;
    font-size: 1.2em;
    font-family: 'Open Sans', sans-serif;
}

#AboutSection #details #About p a{
    color:rgb(110, 9, 9);
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 800px) {
    #AboutSection #details #About h1{
        font-size: 2.5em;
    }

    #AboutSection #details #About p{
        font-size: 1em;
        padding: 0px 15px;
    }
}

@media only screen and (max-width: 480px){
    #AboutSection #details #About p{
        font-size: 0.9em;
        padding: 0px 5px;
    }
}
