@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body{
    font-family: 'Oswald', sans-serif;
    color:rgb(221, 221, 221);
    overflow-x: hidden;
    background: rgb(8, 10, 12);
}

#contact{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    min-height: 100vh;
    background: rgb(8, 10, 12);
}

#contact #contactBox{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(14, 17, 20);
    width:55%;
    min-height:80%;
    padding: 25px 0;
    text-align: center;
}

#contact #contactBox h1{
    font-size: 3em;
    margin-bottom: 20px;
}

#contact #contactBox p{
    font-size: 1.5em;
    margin-bottom: 20px;
    width: 90%;
    font-family: 'Open Sans', sans-serif;
}

#contact #contactBox form{
    width: 100%; 
    height: auto;
    padding: 15px 20px;
}

#contact #contactBox form label{
    font-size: 1.3em;
}

#contact #contactBox form input{
     margin: 10px 0;
     height: 35px;
     width: 75%;
     padding-left: 5px;
     font-size: 1em;
     border:none;
     font-family: 'Open Sans', sans-serif;
     border-radius: 5px;
}

#contact #contactBox form #submit{
    width: 120px;
    background:rgb(60, 3, 3);
    color:rgb(221, 221, 221);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    text-align: center;
}

#contact #contactBox form #submit:hover{
    background: rgb(48, 2, 2);
}

#contact #contactBox form textarea{
    margin: 10px 0;
    font-size: 1.3em;
    height: 150px;
    width: 75%;
    padding-left: 5px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 5px;
}

#contact #contactBox #contactsocials{
    margin: 10px 0;
    text-align: center;
}

#contact #contactBox #contactsocials .social{
    font-size: 2em;
    margin: 0 50px;
}

@media only screen and (max-width: 1000px){
    #contact #contactBox{
        width: 90%;
    }
    #contact #contactBox #contactsocials .social{
        margin: 0 25px;
    }
}

@media only screen and (max-width: 385px){
    #contact #contactBox h1{
        font-size: 2.5em;
    }

    #contact #contactBox p{
        font-size: 1em;
    }

    #contact #contactBox form label{
        font-size: 1em;
    }

    #contact #contactBox form input{
        width: 95%;
    }

    #contact #contactBox form textarea{
        width: 95%;
    }

}